<template>
    <div class="docs">
        <link href="https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital@0;1&display=swap" rel="stylesheet">
        <div @click="closeNav" :class="[ 'nav-mask', openNav ? 'open' : '' ]"></div>
        <div class="container">
            <nav :class="[ 'docs-nav', openNav ? 'open' : '' ]">
                <div class="nav-title" @click="toggleNav">
                    <div class="toggle-btn">
                        <div class="icon">+</div><span>{{ openNav ? $t('page.docs.close_catalog') : $t('page.docs.catalog') }}</span>
                    </div>
                </div>
                <div class="nav-content">
                    <div class="page-title">
                        <h1><i class="iconfont">&#xe616;</i> {{ $t('page.docs.title') }}</h1>
                    </div>
                    <div class="category">
                        <router-link v-for="category in cateList" :key="category" :to="'/docs/' + category">
                            {{ $t('page.docs.category.'+category) }}
                        </router-link>
                    </div>
                    <div v-if="docsMeta[docsCate]" class="page-list">
                        <section v-for="(subCate, index) in Object.keys(docsMeta[docsCate]).sort().map(key => { return {pages: docsMeta[docsCate][key], name: key} })" :key="index">
                            <h2 v-if="subCate.name != 'default'">{{ subCate.name }}</h2>
                            <div v-for="page in subCate.pages" :key="page.title" class="page-link">
                                <router-link @click.native="closeNav" :to="'/docs/' + docsCate + '/' /*+ subCate.name + '/'*/ + page.urlName">{{ page.title }}</router-link>
                                <div v-if="page.path == `${docsCate}/${docsPage}.md`" id="docs-toc"></div>
                            </div>
                        </section>
                    </div>
                </div>
            </nav>
            <article>
                <div class="content">
                    <h1 class="article-title">
                        {{ docsPageInfo.title }}
                    </h1>
                    <vue-markdown :toc="true" :toc-id="'docs-toc'" @rendered="handleRendered" :source="articleContent"></vue-markdown>
                </div>
            </article>
        </div>
    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

import 'highlight.js/styles/tomorrow.css'

import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'
import csharp from 'highlight.js/lib/languages/csharp'
hljs.registerLanguage('javascript', javascript)
hljs.registerLanguage('csharp', csharp)

export default {
    name: 'Docs',
    data: () => {
        return {
            docContent: '',
            docsMeta: {},
            cateList: [],
            articleContent: '',
            categoryOrder: {
                'examples': 100,
                'editor': 0,
                'sdk': 10,
                'unity': 20,
                'faq': '50'
            },
            openNav: false,
        }
    },
    computed: {
        docsCate() {
            return this.$route.params.category
        },
        docsSubCate() {
            return 'default' //this.$route.params.subcate || 'default'
        },
        docsPage() {
            return this.$route.params.page || 'index'
        },
        docsPageInfo() {
            return Object.keys(this.docsMeta).length ?
                this.docsMeta[this.docsCate][this.docsSubCate].find(page => page.urlName == this.docsPage) :
                {
                    order: 0,
                    path: '',
                    title: this.$t('page.docs.loading'),
                    urlName: 'loading'
                }
        }
        // articleContent() {
        //     const pageInfo = this.docsMeta[this.docsCate][this.docsSubCate].find(page => page.urlName == this.docsPage)
        //     return pageInfo//import('../docs/' + pageInfo.path)
        // }
    },
    watch: {
        $route: {
            immediate: true,
            handler: function () {
                const cateName = this.$t('page.docs.category.'+this.docsCate)
                document.title = this.$t('meta.ReadDocs.loadedTitle', {
                    page: this.docsPageInfo.title,
                    category: cateName
                })
                this.loadArticle()
            }
        }
    },
    methods: {
        async loadArticle() {
            const page = this.docsPageInfo
            this.articleContent = this.$t('page.docs.loading')

            if (!page.path) return
            
            const content = (await import('../docs/' + this.$i18n.locale + '/' + page.path)).default
            if (this.docsPageInfo == page) {
                this.articleContent = content.replace(/^---[\s\S]+?---\s*/g, '')
            }

            const cateName = this.$t('page.docs.category.'+this.docsCate)
            document.title = this.$t('meta.ReadDocs.loadedTitle', {
                page: this.docsPageInfo.title,
                category: cateName
            })
        },
        handleRendered() {
            this.$nextTick(() => {
                if (this.$route.hash) {
                    const tempLink = document.createElement('a')
                    tempLink.href = decodeURI(this.$route.hash)
                    tempLink.click()
                    tempLink.remove()
                }
                document.querySelectorAll('pre').forEach(el => {
                    hljs.highlightElement(el)
                })
            })
        },
        toggleNav() {
            this.openNav = !this.openNav
            if (this.openNav) {
                document.body.classList.add('mobile-lock-scroll')
            } else {
                document.body.classList.remove('mobile-lock-scroll')
            }
        },
        closeNav() {
            this.openNav = false
            document.body.classList.remove('mobile-lock-scroll')
        }
    },
    beforeCreate: async function () {
        const docsInfo = await import(`../docs/${ this.$i18n.locale }/content.json`)

        docsInfo.pages.forEach(page => {
            const pagePos = page.path.split('/')

            page.path = page.path.replace('.html', '.md')
            page.urlName = page.path.split('/').pop().slice(0, -3)

            if (!this.docsMeta[pagePos[0]]) { this.$set(this.docsMeta, pagePos[0], {}) }

            if (pagePos.length == 2) {
                if (!this.docsMeta[pagePos[0]]["default"]) { this.$set(this.docsMeta[pagePos[0]], "default", []) }
                this.docsMeta[pagePos[0]]["default"].push(page)
            } else {
                if (!this.docsMeta[pagePos[0]][pagePos[1]]) { this.$set(this.docsMeta[pagePos[0]], pagePos[1], []) }
                this.docsMeta[pagePos[0]][pagePos[1]].push(page)
            }
        })

        Object.values(this.docsMeta).forEach(category => {
            Object.values(category).forEach(pageList => {
                pageList.sort((a, b) => a.order - b.order)
            })
        })

        Object.keys(this.docsMeta).forEach(category => {
            this.cateList.push(category)
        })
        this.cateList.sort((a, b) => this.categoryOrder[a] - this.categoryOrder[b])

        this.loadArticle()
    },
    components: {
        VueMarkdown,
    }
}
</script>

<style lang="scss" scoped>
.docs {
    position: relative;

    &::after {
        position: absolute;
        width: 100%;
        height: 150px;
        left: 0;
        top: 0;
        z-index: 10;
        background: linear-gradient(180deg, rgba(233,233,233,1) 0%, rgba(255,255,255,1) 100%);
        content: '';
        display: flex;
    }
    .container {
        display: flex;
        padding-top: 80px;
        padding-bottom: 60px;
        position: relative;
        z-index: 50;
        
        nav {
            width: 250px;

            .nav-title {
                display: none;
            }
            .page-title {
                font-size: 26px;
                display: flex;
                align-items: center;
                line-height: 1;
                margin-bottom: 40px;

                h1 {
                    margin: 0;
                }
                .iconfont {
                    font-size: 28px;
                    font-weight: normal;
                    color: var(--p);
                }
            }
            .category {
                a {
                    display: block;
                    padding: 9px 15px;
                    margin-bottom: 10px;
                    border-radius: 6px;
                    background: rgba(#FF971A, .15);
                    color: var(--p);
                    font-weight: bold;
                    text-decoration: none;

                    &:hover {
                        background: rgba(#FF971A, .25);
                    }
                    &.router-link-active {
                        background: var(--p);
                        color: #fff;
                        cursor: default;
                    }
                }
            }
            .page-list {
                margin-top: 40px;
                padding-top: 40px;
                border-top: 4px solid rgba(#000, .05);

                .page-link {
                    margin-bottom: 7px;

                    > a {
                        font-size: 18px;
                        text-decoration: none;
                        color: #000;

                        &:hover, &.router-link-active {
                            color: var(--p);
                        }
                    }
                }
                h2 {
                    margin-bottom: 12px;
                    margin-top: 30px;
                }
            }
            @media (max-width: 680px) {
                width: 100%;
                width: 100vw;
                // height: 100vh;
                height: calc(100vh - 40px);
                position: fixed;
                left: 0;
                // bottom: 40px;
                bottom: calc(90px - 100vh);
                display: flex;
                flex-direction: column;
                background: #fff;
                border-radius: 16px 16px 0 0;
                box-shadow: 0 0 10px rgba(#000, .2);
                transition: bottom .5s;
                z-index: 100;
                overflow: hidden;

                .nav-title {
                    display: flex;
                    height: 50px;
                    border-bottom: 1px solid rgba(#000, .1);
                    align-items: center;
                    justify-content: center;
                    background: var(--p);
                    color: #fff;

                    .icon {
                        margin-right: 6px;
                        display: block;
                        font-size: 24px;
                        transition: transform .3s;
                    }
                    .toggle-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 25px;
                        background: rgba(#000, .07);
                        border-radius: 999px;
                    }
                }
                .nav-content {
                    height: 1%;
                    flex-grow: 1;
                    overflow-y: auto;
                    padding: 30px 20px;
                }
                &.open {
                    bottom: 0;
                    z-index: 400;

                    .nav-title {
                        .icon {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
        article {
            width: 1%;
            flex-grow: 1;
            padding: 0 30px;

            .article-title {
                font-size: 56px;
                margin-top: 0;
                line-height: 1.3;
            }
            .content {
                max-width: 720px;
                margin: 0 auto;
            }
            @media (max-width: 680px) {
                padding: 0;
            }
        }
    }
}

.nav-mask {
    display: none;

    @media (max-width: 680px) {
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 50;
        background: rgba(#000, .2);

        &.open {
            display: block;
        }
    }
}
</style>

<style lang="scss">

.docs {
    article {
        img {
            max-width: 100%;
        }
        p {
            font-size: 16px;
            line-height: 1.8;

            * {
                vertical-align: middle;
            }
            a {
                vertical-align: baseline;
            }
        }
        li {
            margin-bottom: .5em;

            code {
                color: var(--p);
                background: rgba(#FF971A, .1);
                border-radius: 4px;
                padding: 3px 5px;
            }
        }
        a {
            color: var(--p);
            font-weight: bold;
        }
        code {
            font-family: 'JetBrains Mono', monospace;
        }
        pre {
            background: #f3f3f3;
            border: 1px solid #ccc;
            border-radius: 6px;
            padding: 15px 0;

            code {
                background: none;
                color: #444;
            }
        }
        h2 {
            font-size: 36px;
            margin-top: 1.3em;
        }
        hr {
            border: none;
            border-bottom: 4px solid rgba(#000, .06);
            margin: 30px 0;
        }
        @media (max-width: 680px) {
            * {
                word-break: break-all;
            }
        }
    }
    #docs-toc {
        .table-of-contents {
            margin: 7px 0;
            padding-left: 16px;
            font-size: 14px;

            ul {
                padding-left: 16px;
            }
            li {
                list-style: none;
                margin-left: 0;
                
                a {
                    text-decoration: none;
                    color: #000;

                    &:hover {
                        color: var(--p);
                    }
                }
            }
        }
    }
}
</style>